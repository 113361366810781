import {
  type ReactNode,
  useRef,
  Suspense,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {Disclosure, Listbox} from '@headlessui/react';
import {defer, type LoaderArgs} from '@shopify/remix-oxygen';
import {
  useLoaderData,
  Await,
  useSearchParams,
  useLocation,
  useTransition,
  useRouteLoaderData,
} from '@remix-run/react';
import {
  AnalyticsPageType,
  Money,
  ShopifyAnalyticsProduct,
  ShopPayButton,
  flattenConnection,
  type SeoHandleFunction,
  type SeoConfig,
} from '@shopify/hydrogen';
import {
  Heading,
  IconCaret,
  IconCheck,
  IconClose,
  ProductGallery,
  ProductSwimlane,
  Section,
  Skeleton,
  Text,
  Link,
  AddToCartButton,
} from '~/components';
import {getExcerpt, getProductsFromOnePage} from '~/lib/utils';
import invariant from 'tiny-invariant';
import clsx from 'clsx';
import type {
  ProductVariant,
  SelectedOptionInput,
  Product as ProductType,
  Shop,
  ProductConnection,
  MediaConnection,
  MediaImage,
} from '@shopify/hydrogen/storefront-api-types';
import {MEDIA_FRAGMENT, PRODUCT_CARD_FRAGMENT} from '~/data/fragments';
import type {Storefront} from '~/lib/type';
import type {Product} from 'schema-dts';

const seo: SeoHandleFunction<typeof loader> = ({data}) => {
  const media = flattenConnection<MediaConnection>(data.product.media).find(
    (media) => media.mediaContentType === 'IMAGE',
  ) as MediaImage | undefined;

  return {
    title: data?.product?.seo?.title ?? data?.product?.title,
    media: media?.image,
    description: (
      data?.product?.seo?.description ??
      data?.product?.description ??
      ''
    )?.slice(0, 150),
    jsonLd: {
      '@context': 'https://schema.org',
      '@type': 'Product',
      brand: data?.product?.vendor,
      name: data?.product?.title,
    },
  } satisfies SeoConfig<Product>;
};

export const handle = {
  seo,
};

export async function loader({params, request, context}: LoaderArgs) {
  const {productHandle} = params;
  invariant(productHandle, 'Missing productHandle param, check route filename');

  const searchParams = new URL(request.url).searchParams;

  const selectedOptions: SelectedOptionInput[] = [];
  searchParams.forEach((value, name) => {
    if (name !== 'pw') {
      selectedOptions.push({name, value});
    }
  });
  console.log('selectedOptions');
  console.log(selectedOptions);

  const {shop, product} = await context.storefront.query<{
    product: ProductType & {selectedVariant?: ProductVariant};
    shop: Shop;
  }>(PRODUCT_QUERY, {
    variables: {
      handle: productHandle,
      selectedOptions,
      country: context.storefront.i18n.country,
      language: context.storefront.i18n.language,
    },
  });

  if (!product?.id) {
    throw new Response(null, {status: 404});
  }

  const recommended = getRecommendedProducts(context.storefront, product.id);
  const firstVariant = product.variants.nodes[0];
  const selectedVariant = product.selectedVariant ?? firstVariant;

  const productAnalytics: ShopifyAnalyticsProduct = {
    productGid: product.id,
    variantGid: selectedVariant.id,
    name: product.title,
    variantName: selectedVariant.title,
    brand: product.vendor,
    price: selectedVariant.price.amount,
  };

  return defer({
    product,
    shop,
    recommended,
    analytics: {
      pageType: AnalyticsPageType.product,
      resourceId: product.id,
      products: [productAnalytics],
      totalValue: parseFloat(selectedVariant.price.amount),
    },
  });
}

export default function Product() {
  const {product, shop, recommended} = useLoaderData<typeof loader>();
  const {onepage} = useRouteLoaderData('root') as any;
  const {media, title, vendor, descriptionHtml} = product;
  const {shippingPolicy, refundPolicy} = shop;

  const featuredProducts = getProductsFromOnePage(onepage).filter(
    (p) => p.id !== product.id,
  );

  const [num, setNum] = useState(0);

  const [bundleVariants, setBundleVariants] = useState(() => {
    type ProdictId = string;
    const bundleVariantIds: Record<
      ProdictId,
      {variantId: string; quantity: number}
    > = {};

    product.bundle?.reference?.items?.references?.nodes?.forEach((item) => {
      const quantities = JSON.parse(item.quantities?.value ?? '[1]') ?? [];
      const variantId = item.product?.reference?.variants?.nodes?.[0]?.id;
      if (variantId) {
        bundleVariantIds[item.product?.reference?.id] = {
          variantId,
          quantity: quantities[0] ?? 1,
        };
      }
    });

    return bundleVariantIds;
  });

  const updateBundleVariantIds = (productId: string, variantId: string) => {
    console.log(productId, variantId);
    setBundleVariants((prev) => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        variantId,
      },
    }));
  };

  const updateBundleVariantQuantity = (productId: string, quantity: number) => {
    console.log(productId, quantity);
    setBundleVariants((prev) => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        quantity,
      },
    }));
  };

  const randomBundleId = Math.random().toString(36).substr(2, 9);

  const variants = product.bundle?.reference?.items?.references?.nodes
    ?.map((item) => item.product?.reference?.variants?.nodes)
    ?.flat();

  const bundlePrice = Object.values(bundleVariants).reduce(
    (acc, item) =>
      acc +
      item.quantity *
        Number(
          variants?.find((variant) => variant.id === item.variantId)?.price
            ?.amount ?? 0,
        ),
    0,
  );

  const parentId = product.bundle?.reference?.parents?.references?.nodes?.find(
    (p) => {
      const parent = p.parent?.reference?.id;
      const quantities = JSON.parse(p.quantities?.value ?? '[1]') ?? [];
      const productIds =
        p.product?.references?.nodes?.map((item: any) => item.id) ?? [];
      return (
        productIds.every(
          (id: string) =>
            bundleVariants[id]?.quantity === quantities[productIds.indexOf(id)],
        ) &&
        Object.entries(bundleVariants).filter(([id, item]) => item.quantity > 0)
          .length === productIds.length
      );
    },
  )?.parent?.reference?.id;

  useEffect(() => {
    console.log(bundleVariants);
    console.log(product.bundle);
    console.log({parentId});
  }, [bundleVariants]);

  return (
    <>
      <Section padding="x" className="!px-4 max-w-5xl mx-auto">
        <div className="grid items-start md:gap-6 lg:gap-8 md:grid-cols-2 lg:grid-cols-2 mt-8">
          <ProductGallery
            media={media.nodes}
            className="md:w-full lg:col-span-1"
          />
          <div className="md:-mb-nav md:top-nav md:-translate-y-nav md:pt-nav hiddenScroll md:overflow-y-scroll max-w-[calc(100vw-2rem)]">
            <section className="flex flex-col max-w-xl lg:max-w-full gap-8 lg:p-6 md:mx-auto md:max-w-sm md:px-0">
              <div className="grid gap-2">
                <Heading as="h1" className="whitespace-normal">
                  {title}
                </Heading>
              </div>
              {product.bundle?.reference && (
                <>
                  <div>
                    <span className="text-lg">
                      {product.bundle.reference.title.value}
                    </span>
                    {product.bundle.reference.items.references.nodes.map(
                      (item) => (
                        <div className="mb-2" key={item.product.id}>
                          <div>{item.product.reference?.title}</div>
                          <div className="flex gap-1">
                            <select
                              value={
                                bundleVariants[item.product.reference?.id]
                                  ?.quantity ?? 1
                              }
                              onChange={(evt) =>
                                updateBundleVariantQuantity(
                                  item.product.reference?.id,
                                  Number(evt.target.value),
                                )
                              }
                              className={
                                JSON.parse(item.quantities?.value ?? '[1]')
                                  ?.length > 1
                                  ? ''
                                  : 'hidden'
                              }
                            >
                              {JSON.parse(item.quantities?.value ?? '[1]').map(
                                (quantity) => (
                                  <option key={quantity} value={quantity}>
                                    {quantity}x
                                  </option>
                                ),
                              )}
                            </select>
                            <select
                              value={
                                bundleVariants[item.product.reference?.id]
                                  ?.variantId ?? ''
                              }
                              className={
                                item.product?.reference?.variants?.nodes
                                  ?.length > 1
                                  ? ''
                                  : 'hidden'
                              }
                              onChange={(evt) =>
                                updateBundleVariantIds(
                                  item.product.reference?.id,
                                  evt.target.value,
                                )
                              }
                            >
                              {item.product.reference.variants.nodes.map(
                                (variant) => (
                                  <option key={variant.id} value={variant.id}>
                                    {variant.title}
                                  </option>
                                ),
                              )}
                            </select>
                          </div>
                        </div>
                      ),
                    )}
                    <div>
                      <Money
                        className="mt-8 text-xl lg:text-2xl"
                        data={{
                          currencyCode: 'EUR',
                          amount: (bundlePrice ?? 0).toFixed(2),
                        }}
                      ></Money>
                      <AddToCartButton
                        lines={[
                          ...Object.values(bundleVariants).map((item) => ({
                            merchandiseId: item.variantId,
                            quantity: item.quantity,
                            attributes: [
                              {
                                key: '_bundle',
                                value: randomBundleId,
                              },
                              ...(parentId
                                ? [
                                    {
                                      key: '_parentId',
                                      value: parentId,
                                    },
                                  ]
                                : []),
                            ],
                          })),
                        ]}
                        variant="secondary"
                        className="mt-8 max-w-[20rem] w-full"
                      >
                        <Text
                          as="span"
                          className="flex items-center justify-center gap-2"
                        >
                          Zum Warenkorb
                        </Text>
                      </AddToCartButton>
                      <div className="mt-8">
                        {descriptionHtml && (
                          <ProductDetail
                            title="Beschreibung"
                            defaultOpen
                            content={descriptionHtml}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {!product.bundle?.reference && (
                <>
                  <ProductForm />
                  <div className="grid gap-4 py-4">
                    {descriptionHtml && (
                      <ProductDetail
                        title="Beschreibung"
                        defaultOpen
                        content={descriptionHtml}
                      />
                    )}
                    {false && shippingPolicy?.body && (
                      <ProductDetail
                        title="Versand"
                        content={getExcerpt(shippingPolicy.body)}
                        learnMore={`/policies/${shippingPolicy.handle}`}
                      />
                    )}
                    {false && refundPolicy?.body && (
                      <ProductDetail
                        title="Rückgabe"
                        content={getExcerpt(refundPolicy.body)}
                        learnMore={`/policies/${refundPolicy.handle}`}
                      />
                    )}
                  </div>
                </>
              )}
            </section>
          </div>
        </div>
      </Section>
      <Suspense fallback={<Skeleton className="h-32" />}>
        <Await
          errorElement="There was a problem loading related products"
          resolve={featuredProducts}
        >
          {(products) =>
            products?.length > 0 && (
              <ProductSwimlane title="Verwandte Produkte" products={products} />
            )
          }
        </Await>
      </Suspense>
      <div className="relative w-full min-h-[120px]"></div>
    </>
  );
}

export function ProductForm() {
  const {product, analytics} = useLoaderData<typeof loader>();

  const [currentSearchParams] = useSearchParams();
  const transition = useTransition();

  /**
   * We update `searchParams` with in-flight request data from `transition` (if available)
   * to create an optimistic UI, e.g. check the product option before the
   * request has completed.
   */
  const searchParams = useMemo(() => {
    return transition.location
      ? new URLSearchParams(transition.location.search)
      : currentSearchParams;
  }, [currentSearchParams, transition]);

  const firstVariant = product.variants.nodes[0];

  /**
   * We're making an explicit choice here to display the product options
   * UI with a default variant, rather than wait for the user to select
   * options first. Developers are welcome to opt-out of this behavior.
   * By default, the first variant's options are used.
   */
  const searchParamsWithDefaults = useMemo<URLSearchParams>(() => {
    const clonedParams = new URLSearchParams(searchParams);

    for (const {name, value} of firstVariant.selectedOptions) {
      if (!searchParams.has(name)) {
        clonedParams.set(name, value);
      }
    }

    return clonedParams;
  }, [searchParams, firstVariant.selectedOptions]);

  /**
   * Likewise, we're defaulting to the first variant for purposes
   * of add to cart if there is none returned from the loader.
   * A developer can opt out of this, too.
   */
  const selectedVariant = product.selectedVariant ?? firstVariant;
  const isOutOfStock = !selectedVariant?.availableForSale;

  const isOnSale =
    selectedVariant?.price?.amount &&
    selectedVariant?.compareAtPrice?.amount &&
    selectedVariant?.price?.amount < selectedVariant?.compareAtPrice?.amount;

  const productAnalytics: ShopifyAnalyticsProduct = {
    ...analytics.products[0],
    quantity: 1,
  };

  return (
    <div className="grid gap-10">
      <div className="grid gap-4">
        <ProductOptions
          options={product.options}
          searchParamsWithDefaults={searchParamsWithDefaults}
        />
        {selectedVariant && (
          <div className="grid items-stretch gap-4">
            <AddToCartButton
              lines={[
                {
                  merchandiseId: selectedVariant.id,
                  quantity: 1,
                },
              ]}
              variant={isOutOfStock ? 'secondary' : 'primary'}
              data-test="add-to-cart"
              analytics={{
                products: [productAnalytics],
                totalValue: parseFloat(productAnalytics.price),
              }}
            >
              {isOutOfStock ? (
                <Text>Sold out</Text>
              ) : (
                <Text
                  as="span"
                  className="flex items-center justify-center gap-2"
                >
                  <span>Zum Warenkorb</span> <span>·</span>{' '}
                  <Money
                    withoutTrailingZeros
                    data={selectedVariant?.price!}
                    as="span"
                  />
                  {isOnSale && (
                    <Money
                      withoutTrailingZeros
                      data={selectedVariant?.compareAtPrice!}
                      as="span"
                      className="opacity-50 strike"
                    />
                  )}
                </Text>
              )}
            </AddToCartButton>
            {!isOutOfStock && (
              <ShopPayButton variantIds={[selectedVariant?.id!]} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function ProductOptions({
  options,
  searchParamsWithDefaults,
}: {
  options: ProductType['options'];
  searchParamsWithDefaults: URLSearchParams;
}) {
  const closeRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      {options
        .filter((option) => option.values.length > 1)
        .map((option) => (
          <div
            key={option.name}
            className="flex flex-col flex-wrap mb-4 gap-y-2 last:mb-0"
          >
            <Heading as="legend" size="lead" className="min-w-[4rem]">
              {option.name}
            </Heading>
            <div className="flex flex-wrap items-baseline gap-4">
              {/**
               * First, we render a bunch of <Link> elements for each option value.
               * When the user clicks one of these buttons, it will hit the loader
               * to get the new data.
               *
               * If there are more than 7 values, we render a dropdown.
               * Otherwise, we just render plain links.
               */}
              {option.values.length > 7 ? (
                <div className="relative w-full">
                  <Listbox>
                    {({open}) => (
                      <>
                        <Listbox.Button
                          ref={closeRef}
                          className={clsx(
                            'flex items-center justify-between w-full py-3 px-4 border border-primary',
                            open
                              ? 'rounded-b md:rounded-t md:rounded-b-none'
                              : 'rounded',
                          )}
                        >
                          <span>
                            {searchParamsWithDefaults.get(option.name)}
                          </span>
                          <IconCaret direction={open ? 'up' : 'down'} />
                        </Listbox.Button>
                        <Listbox.Options
                          className={clsx(
                            'border-primary bg-secondary absolute bottom-12 z-30 grid h-48 w-full overflow-y-scroll rounded-t border px-2 py-2 transition-[max-height] duration-150 sm:bottom-auto md:rounded-b md:rounded-t-none md:border-t-0 md:border-b',
                            open ? 'max-h-48' : 'max-h-0',
                          )}
                        >
                          {option.values.map((value) => (
                            <Listbox.Option
                              key={`option-${option.name}-${value}`}
                              value={value}
                            >
                              {({active}) => (
                                <ProductOptionLink
                                  optionName={option.name}
                                  optionValue={value}
                                  className={clsx(
                                    'text-primary w-full p-2 transition rounded flex justify-start items-center text-left cursor-pointer',
                                    active && 'bg-primary/10',
                                  )}
                                  searchParams={searchParamsWithDefaults}
                                  onClick={() => {
                                    if (!closeRef?.current) return;
                                    closeRef.current.click();
                                  }}
                                >
                                  {value}
                                  {searchParamsWithDefaults.get(option.name) ===
                                    value && (
                                    <span className="ml-2">
                                      <IconCheck />
                                    </span>
                                  )}
                                </ProductOptionLink>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </>
                    )}
                  </Listbox>
                </div>
              ) : (
                <>
                  {option.values.map((value) => {
                    const checked =
                      searchParamsWithDefaults.get(option.name) === value;
                    const id = `option-${option.name}-${value}`;

                    return (
                      <Text key={id}>
                        <ProductOptionLink
                          optionName={option.name}
                          optionValue={value}
                          searchParams={searchParamsWithDefaults}
                          className={clsx(
                            'leading-none py-1 border-b-[1.5px] cursor-pointer transition-all duration-200',
                            checked ? 'border-primary/50' : 'border-primary/0',
                          )}
                        />
                      </Text>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        ))}
    </>
  );
}

function ProductOptionLink({
  optionName,
  optionValue,
  searchParams,
  children,
  ...props
}: {
  optionName: string;
  optionValue: string;
  searchParams: URLSearchParams;
  children?: ReactNode;
  [key: string]: any;
}) {
  const {pathname} = useLocation();
  const isLangPathname = /\/[a-zA-Z]{2}-[a-zA-Z]{2}\//g.test(pathname);
  // fixes internalized pathname
  const path = isLangPathname
    ? `/${pathname.split('/').slice(2).join('/')}`
    : pathname;

  const clonedSearchParams = new URLSearchParams(searchParams);
  clonedSearchParams.set(optionName, optionValue);

  return (
    <Link
      {...props}
      preventScrollReset
      prefetch="intent"
      replace
      to={`${path}?${clonedSearchParams.toString()}`}
    >
      {children ?? optionValue}
    </Link>
  );
}

export function ProductDetail({
  title,
  content,
  learnMore,
  defaultOpen,
}: {
  title: string;
  content: string;
  learnMore?: string;
  defaultOpen?: boolean;
}) {
  return (
    <Disclosure
      key={title}
      defaultOpen={defaultOpen}
      as="div"
      className="grid w-full gap-2"
    >
      {({open}) => (
        <>
          <Disclosure.Button className="text-left">
            <div className="flex justify-between">
              <Text size="lead" as="h4">
                {title}
              </Text>
              <IconClose
                className={clsx(
                  'transition-transform transform-gpu duration-200',
                  !open && 'rotate-[45deg]',
                )}
              />
            </div>
          </Disclosure.Button>

          <Disclosure.Panel className={'pb-4 pt-2 grid gap-2'}>
            <div
              className="prose "
              dangerouslySetInnerHTML={{__html: content}}
            />
            {learnMore && (
              <div className="">
                <Link
                  className="pb-px border-b border-primary/30 text-primary/50"
                  to={learnMore}
                >
                  Learn more
                </Link>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export const PRODUCT_VARIANT_FRAGMENT = `#graphql
  fragment ProductVariantFragment on ProductVariant {
    id
    availableForSale
    selectedOptions {
      name
      value
    }
    image {
      id
      url
      altText
      width
      height
    }
    price {
      amount
      currencyCode
    }
    compareAtPrice {
      amount
      currencyCode
    }
    sku
    title
    unitPrice {
      amount
      currencyCode
    }
    product {
      title
      handle
    }
  }
`;

const PRODUCT_QUERY = `#graphql
  ${MEDIA_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
  query Product(
    $country: CountryCode
    $language: LanguageCode
    $handle: String!
    $selectedOptions: [SelectedOptionInput!]!
  ) @inContext(country: $country, language: $language) {
    product(handle: $handle) {
      id
      title
      vendor
      handle
      descriptionHtml
      description
      bundle: metafield(namespace: "custom", key: "bundle") {
        reference {
          ...on Metaobject {
            title: field(key: "title") {
              value
            }
            parents: field(key: "parent_products") {
              references(first: 25) {
                nodes {
                  ...on Metaobject {
                    id
                    product: field(key: "products") {
                      references(first: 5) {
                        nodes {
                          ...on Product {
                            id
                          }
                        }
                      }
                    }
                    quantities: field(key: "quantities") {
                      value
                    }
                    parent: field(key: "parent") {
                      reference {
                        ...on ProductVariant {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
            items: field(key: "items") {
              references(first: 10) {
                nodes {
                  ...on Metaobject {
                    product: field(key: "product") {
                      reference {
                        ...on Product {
                          id
                          title
                          variants(first: 25) {
                            nodes {
                              ...ProductVariantFragment
                            }
                          }
                        }
                      }
                    }
                    quantities: field(key: "quantities") {
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
      options {
        name
        values
      }
      selectedVariant: variantBySelectedOptions(selectedOptions: $selectedOptions) {
        ...ProductVariantFragment
      }
      media(first: 15) {
        nodes {
          ...Media
        }
      }
      variants(first: 1) {
        nodes {
          ...ProductVariantFragment
        }
      }
      seo {
        description
        title
      }
    }
    shop {
      name
      shippingPolicy {
        body
        handle
      }
      refundPolicy {
        body
        handle
      }
    }
  }
`;

const RECOMMENDED_PRODUCTS_QUERY = `#graphql
  ${PRODUCT_CARD_FRAGMENT}
  query productRecommendations(
    $productId: ID!
    $count: Int
    $country: CountryCode
    $language: LanguageCode
  ) @inContext(country: $country, language: $language) {
    recommended: productRecommendations(productId: $productId) {
      ...ProductCard
    }
    additional: products(first: $count, sortKey: BEST_SELLING) {
      nodes {
        ...ProductCard
      }
    }
  }
`;

async function getRecommendedProducts(
  storefront: Storefront,
  productId: string,
) {
  const products = await storefront.query<{
    recommended: ProductType[];
    additional: ProductConnection;
  }>(RECOMMENDED_PRODUCTS_QUERY, {
    variables: {productId, count: 12},
  });

  invariant(products, 'No data returned from Shopify API');

  const mergedProducts = products.recommended
    .concat(products.additional.nodes)
    .filter(
      (value, index, array) =>
        array.findIndex((value2) => value2.id === value.id) === index,
    );

  const originalProduct = mergedProducts
    .map((item: ProductType) => item.id)
    .indexOf(productId);

  mergedProducts.splice(originalProduct, 1);

  return mergedProducts;
}
